'use client'
import useRouter from '@/src/hooks/Router/useRouter'
import { useNavigationEvent } from '@/src/hooks/useNavigationEvent'
import { usePathname } from '@/src/hooks/usePathname'
import { selectRedirectUrl, setRedirectUrl } from '@/src/store/slices/authSlice'
import {
  setLastVisitedLobby,
  setLastVisitedPage,
} from '@/src/utils/localStorage'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default function AuthRedirect() {
  const dispatch = useDispatch()
  const redirectUrl = useSelector(selectRedirectUrl)
  const router = useRouter()
  const pathname = usePathname() || ''

  const handleConsistentRoutes = useCallback(() => {
    // Store last visited page persistant in localstorage
    const ignoreRoutes = [
      '/login',
      '/pnp',
      '/pnp/auth',
      '/pnp/deposit',
      '/pnp/withdraw',
      '/logged-out',
      '/inbox-messages',
      '/withdraw/pending',
    ]
    const lobbyRoutes = ['/', '/live', '/sports']
    // used for redirecting after payment flow is completed
    if (!ignoreRoutes.includes(pathname)) {
      setLastVisitedPage(pathname)
    }
    if (lobbyRoutes.includes(pathname)) {
      setLastVisitedPage(null)
      setLastVisitedLobby(pathname)
    }
    // we need to track last lobby user was in
  }, [pathname])

  useEffect(() => {
    if (!redirectUrl) return

    const shouldNotRedirect = ['/error', '/static/responsible-gambling'].some(
      (path) => pathname.startsWith(path),
    )

    // Ignore redirectUrl if it's the same as the current path
    // or if already in error page
    if (pathname === redirectUrl || shouldNotRedirect) {
      dispatch(setRedirectUrl(null)) // Reset the redirectUrl in state after using it
    } else if (pathname !== redirectUrl) {
      router.replace(redirectUrl)
    }
  }, [dispatch, pathname, redirectUrl, router])

  // Handle
  useNavigationEvent(handleConsistentRoutes)

  // Modals container setup
  useEffect(() => {
    const wrapper = document.createElement('div')
    wrapper.setAttribute('id', 'app-main')
    document.body.appendChild(wrapper)

    // Cleanup effect.
    return () => {
      if (wrapper?.parentNode) {
        wrapper.parentNode?.removeChild(wrapper)
      }
    }
  }, [])

  return null
}
