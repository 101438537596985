'use client'
import { gtmReportWebVitals } from '@/src/services/gtm_factory'
import { useReportWebVitals } from 'next/web-vitals'

export function WebVitals() {
  useReportWebVitals((metric) => {
    gtmReportWebVitals(metric)
  })
  return null
}
