import { api } from '@/src/services'
import {
  EmplomentListDropdownTypes,
  TimeConfigType,
  TranslatedEmploymentDropdownTypes,
} from '@/src/services/types/config'
import { ConfigType } from '@/src/services/types/config.tsx'
import { mapEmploymentListToSelect } from '@/src/services/utils/index.tsx'

const configApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<ConfigType, void>({
      query: () => `config/settings`,
      // NOTE: Hack to make date parsing work in safari
      // safari does not support parsing date format: 'YYYY-MM-DD'
      transformResponse: ({
        serviceTime,
        realityCheckValues,
        timeOutPeriodsByCountry,
        ...rest
      }) => {
        return {
          serviceTime: serviceTime
            ? new Date(`${serviceTime.replace(/\s/, 'T')}Z`).getTime()
            : new Date().getTime(),
          realityCheckValues: realityCheckValues
            ? realityCheckValues
                .split(',')
                .map((values: string) => +values)
                .sort((a: number, b: number) => b - a)
            : [],
          timeOutPeriodsByCountry: timeOutPeriodsByCountry
            ? Object.assign(
                {},
                ...timeOutPeriodsByCountry.split(';').map((param: string) => {
                  const _params = param.split('=')
                  return { [_params[0]]: _params[1].split(',') }
                }),
              )
            : {},
          ...rest,
        } as ConfigType
      },
    }),

    getDropdowns: builder.query<
      EmplomentListDropdownTypes,
      { locale?: string } | null
    >({
      query: () => `config/dropdown/settings`,
      transformResponse: (
        response: TranslatedEmploymentDropdownTypes,
        _meta,
        args,
      ) => {
        const slicedResponse = response[args?.locale?.slice(0, 2) || 'en']
        const transformedResponse = {
          occupations: mapEmploymentListToSelect(
            slicedResponse?.occupations,
            'name',
          ),
          industries: mapEmploymentListToSelect(
            slicedResponse?.industries,
            'label',
          ),
          titles: mapEmploymentListToSelect(slicedResponse?.titles, 'label'),
        }
        return transformedResponse as EmplomentListDropdownTypes
      },
    }),
    getTimeConfig: builder.query<TimeConfigType, void>({
      query: () => `config/timeLimit/amounts`,
    }),
    getCountry: builder.query<{ country: string }, void>({
      query: () => `config/country`,
    }),
  }),
  overrideExisting: process.env.NODE_ENV === 'development',
})

export const {
  useGetSettingsQuery,
  useGetTimeConfigQuery,
  useGetDropdownsQuery,
  useGetCountryQuery,
} = configApi

//SSR endpoints
export const { getSettings } = configApi.endpoints
export default configApi
