const timeFormatter = (time: number) => {
  const doubleDigit = (digit: number) => {
    return digit < 10 ? '0' + digit : digit
  }
  const hours = doubleDigit(Math.floor((time / 3600000) % 24))
  const minutes = doubleDigit(Math.round((time / 60000) % 60))

  return `${hours}:${minutes}`
}

export { timeFormatter }
