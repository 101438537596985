'use client'
import { selectAllowShowIntercom } from '@/src/store/slices/uiSlice.ts'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export default function Intercom() {
  const allowShowIntercom = useSelector(selectAllowShowIntercom)

  useEffect(() => {
    if (allowShowIntercom) {
      ;(async () => {
        const { initiateIntercom } = await import('@/src/utils/intercome.ts')
        initiateIntercom()
      })()
    }
  }, [allowShowIntercom])

  return null
}
