'use client'
import { imageProps } from '@/src/components/ImageSrcSet/LobbyPromoImage.tsx'
import { preload } from 'react-dom'

const PreloadLobbyImage = () => {
  const { desktop, mobile } = imageProps({})

  preload('/images/new/bg-desktop-updated-mobile.jpg', {
    as: 'image',
    imageSrcSet: mobile,
    fetchPriority: 'high',
  })
  preload('/images/new/bg-desktop-updated.jpg', {
    as: 'image',
    imageSrcSet: desktop,
    fetchPriority: 'high',
  })

  return null
}

export default PreloadLobbyImage
