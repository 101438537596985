'use client'
import useAuthGuard from '@/src/hooks/useAuthGuard'
//import { useGetConfigQuery } from '@/src/services/fastTrack'
import { useGetFtAuthMutation } from '@/src/services/fastTrackFusionUrl'
import { useGetAvailableSessionQuery } from '@/src/services/player'
import { useEffect } from 'react'

//import PusherSetup from '@/src/components/FastTrack/pusherSetup'
import { setFTAuthToken } from '@/src/store/slices/fastTrackSlice'
import { addBreadcrumb } from '@sentry/nextjs'
import dynamic from 'next/dynamic'
import { useDispatch } from 'react-redux'
const FastTrack = () => {
  const dispatch = useDispatch()
  const { isLoggedIn } = useAuthGuard()
  const { data: token } = useGetAvailableSessionQuery(undefined, {
    skip: !isLoggedIn,
  })

  const PusherSetup = dynamic(
    () => import('@/src/components/FastTrack/pusherSetup'),
    {
      ssr: false,
    },
  )

  // This is only called to get the config which will not be changed
  // They are then manuelly set to env variables
  // Both for stage and prod
  // useGetConfigQuery(undefined, {
  //   skip: !isLoggedIn,
  // })

  const [getFtAuth, { data: ftUserObj }] = useGetFtAuthMutation()

  useEffect(() => {
    if (typeof token === 'string') {
      getFtAuth(token)
    }
  }, [token, getFtAuth])

  useEffect(() => {
    if (ftUserObj?.Success === false) {
      addBreadcrumb({
        category: 'fastTrack',
        message: 'Failed to authenticate token',
        data: ftUserObj?.Errors,
        level: 'info',
      })
    }
    if (ftUserObj?.Data?.Authentication?.['IsAuthenticated:']) {
      dispatch(setFTAuthToken(ftUserObj.Data?.Authentication?.AuthToken))
    }
  }, [ftUserObj, dispatch])

  return isLoggedIn && token && ftUserObj ? (
    <PusherSetup ftUserObj={ftUserObj} isLoggedIn={isLoggedIn} token={token} />
  ) : null
}

export default FastTrack
