'use client'
import useClock from '@/src/contexts/hooks/useClock.ts'
import useSessionTime from '@/src/contexts/hooks/useSessionTime.ts'
import { createContext, useContext } from 'react'

interface TimeContextType {
  time: string
  sessionTime: string
}

const TimeContext = createContext<TimeContextType | undefined>(undefined)

export const useTime = (): TimeContextType => {
  const context = useContext(TimeContext)
  if (context === undefined) {
    throw new Error('useTime must be used within a TimeProvider')
  }
  return context
}

type TimeProviderProps = {
  children: React.ReactNode
}

export const TimeProvider = ({ children }: TimeProviderProps) => {
  const { time } = useClock()
  const { sessionTimeStr: sessionTime } = useSessionTime()

  return (
    <TimeContext.Provider value={{ time, sessionTime }}>
      {children}
    </TimeContext.Provider>
  )
}
