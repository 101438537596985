import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/node_modules/next/font/local/target.css?{\"path\":\"src/const/font.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/Neusa/Neusa-Medium.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Neusa/Neusa-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"}],\"variable\":\"--font-neusa\"}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/app/[lang]/LayoutIntercom.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/FastTrack/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/ImageSrcSet/preloadLobbyImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageProvider"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/LanguageProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/MyAffiliate/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/NavigationEvents/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/WebVitals/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TimeProvider"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/contexts/timeContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UIContextProvider"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/contexts/ui.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/store/redux/providers.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/src/styles/globals.scss");
