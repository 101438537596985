import { usePathname as useNextPathname } from 'next/navigation'
import { useEffect, useState } from 'react'

const usePathname = () => {
  const pathname = useNextPathname()

  const [pathnameLang, setPathnameLang] = useState<string>(
    pathname.substring(3),
  )

  useEffect(() => {
    const _path = pathname.substring(3)

    _path === '' ? setPathnameLang('/') : setPathnameLang(_path)
  }, [pathname])

  return pathnameLang
}

export { usePathname }
