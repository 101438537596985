import { ImageProps, getImageProps } from 'next/image'
type CustomImageProps = ImageProps & {
  fetchPriority?: 'high' | 'low' | 'auto'
}

type LobbyPromoImageProps = {
  classNames?: string
  css?: React.CSSProperties
}

type commonProps = Pick<
  CustomImageProps,
  'alt' | 'sizes' | 'fetchPriority' | 'className' | 'style' | 'priority' | 'rel'
>

const imageProps = ({ classNames, css }: LobbyPromoImageProps) => {
  const common: commonProps = {
    alt: 'LobbyPromo',
    sizes: '100vw',
    className: classNames,
    style: css,
  }

  const {
    props: { srcSet: desktop },
  } = getImageProps({
    ...common,
    src: '/images/new/bg-desktop-updated.jpg',
    width: 5120,
    height: 1000,
  })

  // 4:3
  const {
    props: { srcSet: mobile, alt, ...rest },
  } = getImageProps({
    ...common,
    src: '/images/new/bg-desktop-updated-mobile.jpg',
    quality: 90,
    width: 1322,
    height: 922,
    priority: true,
  })

  return { desktop, mobile, alt, rest, common }
}

const LobbyPromoImage = ({ classNames, css }: LobbyPromoImageProps) => {
  const { desktop, mobile, rest, alt } = imageProps({ classNames, css })

  return (
    <picture>
      <source media='(max-width: 767px)' srcSet={mobile} />
      <source media='(min-width: 768px)' srcSet={desktop} />
      <img alt={alt} {...rest} />
    </picture>
  )
}
export default LobbyPromoImage

export { imageProps }
