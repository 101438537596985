import I18N from '@/src/app/_i18n/i18nConfig.ts'
import { SupportedLangsType } from '@/src/app/_i18n/settings.tsx'
import { lang_codes } from '@/src/utils/middleware/isoLanguages.ts'
import { NextRequest, NextResponse } from 'next/server'

const i18n = I18N.i18n
const { locales } = i18n

const getLang = (request: NextRequest) => {
  const cookieLang = request.cookies.get('_lang')?.value

  if (locales.some((locale) => cookieLang === locale)) {
    return cookieLang as SupportedLangsType
  }

  return i18n.defaultLocale
}

export const isSupportedLang = (pathname: string) => {
  // Check if the pathname starts with one of the supported languages ex: /fi
  return locales.some(
    (locale) => pathname.startsWith(`/${locale}/`) || pathname === `/${locale}`,
  )
}

const getCurrentLang = (pathname: string) => {
  return pathname.split('/').filter(Boolean)[0]
}

const handleLangCookie = (request: NextRequest, response: NextResponse) => {
  // Check if the _lang cookie exists
  const langCookie = request.cookies.get('_lang')
  const currentLang = getCurrentLang(request.nextUrl.pathname)

  if (
    !langCookie ||
    !i18n.locales.includes(langCookie.value as SupportedLangsType)
  ) {
    response.cookies.set('_lang', i18n.defaultLocale)
  }

  // Set _lang cookie if the pathname has a locale and is supported
  if (
    isSupportedLang(request.nextUrl.pathname) &&
    currentLang !== langCookie?.value
  ) {
    response.cookies.set('_lang', currentLang)
  }
}

const handleTrailingSlashRedirect = (
  request: NextRequest,
  lang: SupportedLangsType,
) => {
  const { pathname } = request.nextUrl
  // Add trailing slash for '/fi' if it doesn't already have one
  if (!request.headers.get('full-traffic-enabled')) return

  if (pathname === `/${lang}` && !pathname.endsWith('/')) {
    return NextResponse.redirect(new URL(`${pathname}/`, request.url))
  }

  // Remove trailing slash for all other paths under `/fi`, but not `/fi/` itself
  if (
    pathname.startsWith(`/${lang}/`) &&
    pathname !== `/${lang}/` &&
    pathname.endsWith('/')
  ) {
    return NextResponse.redirect(new URL(pathname.slice(0, -1), request.url))
  }
}

const handleRedirectLanguages = (request: NextRequest) => {
  // Check if there is any supported locale in the pathname
  const { pathname } = request.nextUrl

  const currentLang = getCurrentLang(pathname)

  const lang = getLang(request)

  // Check if pathname without lang ex: /live
  // @ts-ignore
  const pathnameWithoutLang = lang_codes.includes(currentLang)
  if (!pathnameWithoutLang) {
    request.nextUrl.pathname = `/${lang}${pathname}`
    return NextResponse.redirect(request.nextUrl)
  }

  // Check if the pathname is a supported language
  if (!isSupportedLang(pathname)) {
    request.nextUrl.pathname = `/${lang}${pathname.slice(3)}`
    return NextResponse.redirect(request.nextUrl)
  }

  return handleTrailingSlashRedirect(request, lang)
}

export { handleLangCookie, handleRedirectLanguages }
