import { getBalance, getPlayer, getPlayerActivity } from '@/src/services/player.tsx'
import { getDepositTransactions } from '@/src/services/transactions.tsx'
import { PlayerType } from '@/src/services/types'
import { GTMClickEventData } from '@/src/services/types/gtm.tsx'
import { makeStore } from '@/src/store/store'
import { sendGTMEvent } from '@next/third-parties/google'
import { NextWebVitalsMetric } from 'next/app'
import { getAffiliateId, getAffiliateToken } from '@/src/utils/localStorage'

// declare global {
//   interface Window {
//     dataLayer: Record<string, unknown>[]
//     gtmTriedUploadingDocument?: () => Promise<void>
//   }
// }

const pushEvent = (event: string, data: Record<string, unknown>) => {
  sendGTMEvent({ event, ...data })
}

// const pushEvent = (
//   eventName: string,
//   event:
//     | Record<string, string | number | boolean | undefined | null>
//     | Record<string, unknown>,
// ) => {
//   // if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev') return
//   store
//     .dispatch(getSiteConfig.initiate({ country: 'FIN' }))
//     .then(({ data }) => {
//       if (data?.serverGtmEvents) {
//         store
//           .dispatch(sendEvent.initiate({ en: eventName, ...event }))
//           .then(({ data }) => {
//             if (data?.error) {
//               captureException(
//                 `ERROR SENDING GTM. ErrorCode: ${data.errorCode}. ErrorMsg: ${data.error}`,
//               )
//             }
//           })
//       } else {
//         const dataLayer = window.dataLayer
//         if (!dataLayer) return
//         dataLayer.push({
//           event: eventName,
//           ...event,
//         })
//       }
//     })
// }

// type GTMValidationEventDataType = {
//   validationType: string
//   formValue: string | number | boolean | Date | null
// }
//
// const gtmValidation = (form: string, data: GTMValidationEventDataType) => {
//   pushEvent('validation', {
//     form,
//     ...data,
//   })
// }

const lastLoginInfo = async () => {
  const { data: userActivity, isError } = await makeStore().dispatch(
    getPlayerActivity.initiate({}),
  )

  let lastLoginTime: number | undefined = undefined
  let hoursSinceLastLogin: number | undefined = undefined
  if (userActivity && !isError) {
    const filterArr = userActivity.activities?.filter((elem) => {
      return elem.activityCode === 'Login' && elem.status === 'SUCCESS'
    })
    if (filterArr?.length > 1) {
      lastLoginTime = filterArr[1].createdTime
      hoursSinceLastLogin = Math.floor(
        (new Date().getTime() / 1000 - lastLoginTime / 1000) / 60 / 60,
      )
    }
  }

  const daysSinceLastLogin = hoursSinceLastLogin
    ? Math.round(hoursSinceLastLogin / 24)
    : 0

  return { lastLoginTime, hoursSinceLastLogin, daysSinceLastLogin }
}

const gtmLogin = async () => {
  const { lastLoginTime } =
    await lastLoginInfo()

  const { data: userDetails } = await makeStore().dispatch(getPlayer.initiate())

  if(!userDetails) return

  const hasPhoneOrEmailVerified =
  !!userDetails.email &&
  !!userDetails.phoneNumber &&
  !!(userDetails.emailVerified || userDetails.phoneNumberVerified)
  const nickName = userDetails?.nickName
  const optional = hasPhoneOrEmailVerified ? {nickName: nickName} : {}
  const offlineReference = userDetails?.offlineReference

  pushEvent('user sings in', {
    userStatus: hasPhoneOrEmailVerified ? 'returning' : 'new-user',
    DL_UserID: nickName,
    DL_AffID: getAffiliateId(),
    DL_AffToken: getAffiliateToken(),
    DL_Device: navigator.userAgent,
    DL_Gender: userDetails?.gender,
    DL_Age: userDetails?.birthDate,
    DL_PreviousLoginTime: lastLoginTime,
    offlineReference,
    ...optional
  })
}

const gtmLogout = async () => {

  const { data: userDetails } = await makeStore().dispatch(getPlayer.initiate())

  if(!userDetails) return

  const hasPhoneOrEmailVerified =
    !!userDetails.email &&
    !!userDetails.phoneNumber &&
    !!(userDetails.emailVerified || userDetails.phoneNumberVerified)
  const nickName = userDetails?.nickName
  const optional = hasPhoneOrEmailVerified ? {nickName: nickName} : {}
  const offlineReference = userDetails?.offlineReference

  const { lastLoginTime } =
    await lastLoginInfo()
 
  pushEvent('user signs out', {
    userStatus: hasPhoneOrEmailVerified ? 'returning' : 'new-user',
    DL_UserID: nickName,
    DL_AffID: getAffiliateId(),
    DL_AffToken: getAffiliateToken(),
    DL_Device: navigator.userAgent,
    DL_Gender: userDetails?.gender,
    DL_Age: userDetails?.birthDate,
    DL_PreviousLoginTime: lastLoginTime,
    offlineReference,
    ...optional
  })
}

const gtmRegister = async () => {
  const { data: userDetails } = await makeStore().dispatch(getPlayer.initiate())

  if(!userDetails) return

  const hasPhoneOrEmailVerified =
    !!userDetails.email &&
    !!userDetails.phoneNumber &&
    !!(userDetails.emailVerified || userDetails.phoneNumberVerified)
  const nickName = userDetails?.nickName
  const optional = hasPhoneOrEmailVerified ? {nickName: nickName} : {}
  const offlineReference = userDetails?.offlineReference

  const { lastLoginTime } =
    await lastLoginInfo()

  pushEvent('registration_success', {
    // ...(await getUserId()),
    DL_UserID: nickName,
    DL_AffID: getAffiliateId(),
    DL_AffToken: getAffiliateToken(),
    DL_Device: navigator.userAgent,
    DL_Gender: userDetails?.gender,
    DL_Age: userDetails?.birthDate,
    DL_PreviousLoginTime: lastLoginTime,
    offlineReference,
    ...optional
  })
}

const gtmDeposit = async () => {
  const { data: depositTransactions } = await makeStore().dispatch(
    getDepositTransactions.initiate({ page: 1 }, { forceRefetch: true }),
  )

  const { data: userBalance } = await makeStore().dispatch(
    getBalance.initiate(undefined, {forceRefetch: true})
  )

  const { data: userDetails } = await makeStore().dispatch(getPlayer.initiate())

  if(!userDetails || !userBalance || !depositTransactions) {
    return
  }

  const offlineReference = userDetails?.offlineReference
  const nickName = userDetails?.nickName
  // Filter only pending, ok, confirmed
  const filteredTransactions = depositTransactions?.transactions?.filter(
    (transaction) =>
      transaction.status === 'pending' ||
      transaction.status === 'ok' ||
      transaction.status === 'confirmed',
  )

  const { amount, promoAmount, currency } =
    filteredTransactions?.[0] || {}
  const isFirstTimeDeposit = filteredTransactions?.length === 1
  const number = filteredTransactions?.length ?? null
  

  const hasPhoneOrEmailVerified =
    !!userDetails.email &&
    !!userDetails.phoneNumber &&
    !!(userDetails.emailVerified || userDetails.phoneNumberVerified)

  const optional = hasPhoneOrEmailVerified ? {nickName: nickName} : {}

  pushEvent(isFirstTimeDeposit ? 'first-deposit' : 'deposit' , {
    promoAmount: promoAmount,
    userStatus: hasPhoneOrEmailVerified ? 'returning' : 'new-user', //status === 'new-user' ? 'new-user' : 'returning',
    userBalance: userBalance?.balance,
    first: isFirstTimeDeposit,
    DL_UserID: userDetails?.nickName,
    DL_AffID: getAffiliateId(),
    DL_AffToken: getAffiliateToken(),
    DL_Amount : amount,
    DL_Currency : currency,
    DL_Transno : number,
    offlineReference,
    optional
  })
}

const gtmRegisterLight = async () => {
  const { daysSinceLastLogin } = await lastLoginInfo()
  pushEvent('Registration_Light', {
    ...(await getUserId()),
    daysSinceLastLogin,
  })
}

const gtmGameStart = async ({
  gameName,
  logged_in = true,
}: {
  gameName?: string
  logged_in?: boolean
}) => {
  pushEvent('Game_Start', {
    ...(await getUserId()),
    game: gameName,
    logged_in,
  })
}



const gtmEmailSet = async () => {
  pushEvent('email_set', {
    ...(await getUserId()),
    logged_in: true,
  })
}

const gtmClick = (data: GTMClickEventData) => {
  pushEvent('Lucky_click', {
    ...data,
    time: new Date().getTime(),
  })
}

// type GTMValidationEventDataType = {
//   validationType: string
//   formValue: string | number | boolean | Date | null
// }

// const gtmValidation = (form: string, data: GTMValidationEventDataType) => {
//   pushEvent('validation', {
//     form,
//     ...data,
//   })
// }

const gtmRegisterStart = async () => {
  pushEvent('Reg_Start', {
    ...(await getUserId()),
  })
}

// const gtmTriedUploadingDocument = async () => {
//   pushEvent('Tried_Uploading_Document', {
//     ...(await getUserId()),
//     userTriedUploadingDocument: true,
//   })
// }

const getUserId = async () => {
  let player: PlayerType | null = null
  // Retry logic becuase we are getting no avaiable session exception sometimes
  for (let attempt = 0; attempt < 5; attempt++) {
    const { data } = await makeStore().dispatch(getPlayer.initiate())
    if (data?.userId) {
      player = data
      break
    }
    await new Promise((r) => setTimeout(r, 250))
  }
  return { userID: player?.userId, affiliateID: player?.affiliateId }
}

const gtmReportWebVitals = async (
  metric: NextWebVitalsMetric & { rating: string },
) => {
  pushEvent('coreWebVitals', {
    webVitalsMesurement: {
      name: metric.name,
      rating: metric.rating,
      id: metric.id,
      valueRounded: Math.round(
        metric.name === 'CLS' ? metric.value * 1000 : metric.value,
      ),
    },
    non_interaction: true,
  })
}

export {
  gtmClick,
  gtmDeposit,
  gtmEmailSet,
  gtmGameStart,
  gtmLogin,
  gtmLogout,
  gtmRegister,
  gtmRegisterLight,
  // gtmPageView,
  gtmRegisterStart,
  gtmReportWebVitals,
}
