'use client'
import { useGetBalanceQuery } from '@/src/services/player'
import {
  selectIsLoggedIn,
  setRedirectUrl,
  isLoggedIn as updateLoggedIn,
} from '@/src/store/slices/authSlice'
import { useAppDispatch } from '@/src/store/store'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

type AuthGuardProps = {
  redirectTo?: string
  redirectIfFound?: 'loggedIn' | 'loggedOut'
}

const useAuthGuard = ({ redirectTo, redirectIfFound }: AuthGuardProps = {}) => {
  const dispatch = useAppDispatch()
  const isLoggedInStore = useSelector(selectIsLoggedIn)
  const [isLoggedInLocal, setIsLoggedInLocal] = useState<boolean | undefined>(
    isLoggedInStore,
  )

  const { data, error } = useGetBalanceQuery()

  useEffect(() => {
    if (data?.error || data?.errorCode) {
      setIsLoggedInLocal(false)
      dispatch(updateLoggedIn(false))
    } else if (data) {
      setIsLoggedInLocal(true)
      dispatch(updateLoggedIn(true))
    }
  }, [data, error, dispatch])

  useEffect(() => {
    if (
      (redirectIfFound === 'loggedIn' && isLoggedInLocal) ||
      (redirectIfFound === 'loggedOut' && isLoggedInLocal === false)
    ) {
      dispatch(setRedirectUrl(redirectTo || '/'))
    }
  }, [dispatch, isLoggedInLocal, redirectIfFound, redirectTo])

  return { isLoggedIn: isLoggedInLocal }
}

export default useAuthGuard
