'use client'
import { useLanguage } from '@/src/components/LanguageProvider'
import { appendLangToUrl } from '@/src/components/LocalizedLink/utls'
import { useRouter as useNextRouter } from 'next/navigation'

type AppRouterInstance = typeof useNextRouter
type PushReplaceOptions = Parameters<ReturnType<AppRouterInstance>['push']>[1]
type PrefetchOptions = Parameters<ReturnType<AppRouterInstance>['prefetch']>[1]

const useRouter = () => {
  const router = useNextRouter()
  const { lang } = useLanguage() // Access the current language from your context

  const push = (url: string, options?: PushReplaceOptions) => {
    router.push(appendLangToUrl(url, lang), options)
  }

  const replace = (url: string, options?: PushReplaceOptions) => {
    router.replace(appendLangToUrl(url, lang), options)
  }

  const prefetch = (url: string, options?: PrefetchOptions) => {
    router.prefetch(appendLangToUrl(url, lang), options)
  }

  return {
    ...router,
    push,
    replace,
    prefetch,
  }
}

export default useRouter
