import { timeFormatter } from '@/src/contexts/utils/timeHelper.ts'
import useAuthGuard from '@/src/hooks/useAuthGuard.tsx'
import { useGetSettingsQuery } from '@/src/services/config.tsx'
import { useGetPlayerQuery } from '@/src/services/player.tsx'
import { useEffect, useState } from 'react'

const useSessionTime = () => {
  const [sessionTime, setSessionTime] = useState(0)
  const [sessionTimeStr, setSessionTimeStr] = useState('')

  const { isLoggedIn } = useAuthGuard()
  const { data: userSettings } = useGetSettingsQuery(undefined, {
    skip: !isLoggedIn,
  })

  const { data: user } = useGetPlayerQuery(undefined, {
    skip: !isLoggedIn,
  })

  useEffect(() => {
    if (sessionTime !== 0 && isLoggedIn === false) {
      setSessionTime(0)
      setSessionTimeStr('')
    }
  }, [isLoggedIn, sessionTime])

  useEffect(() => {
    if (sessionTime === 0) return
    setSessionTimeStr(timeFormatter(sessionTime))
  }, [sessionTime])

  useEffect(() => {
    if (!userSettings || !user) return

    setSessionTime(
      Math.abs((userSettings?.serviceTime ?? 0) - (user?.lastLoginTime ?? 0)),
    )
  }, [userSettings, user])

  useEffect(() => {
    if (!isLoggedIn) return

    const interval = setInterval(() => {
      setSessionTime(sessionTime + 10000)
    }, 10000)

    return () => interval && clearInterval(interval)
  }, [isLoggedIn, sessionTime, setSessionTime])

  return { sessionTimeStr }
}

export default useSessionTime
