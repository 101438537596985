'use client'
import { usePathname } from '@/src/hooks/usePathname'
import { useEffect, useRef } from 'react'

export const useNavigationEvent = (
  onPathnameChange: (_url: string) => void,
) => {
  const pathname = usePathname()

  const savedPathNameRef = useRef(pathname)

  useEffect(() => {
    if (savedPathNameRef.current !== pathname) {
      onPathnameChange(pathname || '/')
      // Update REF
      savedPathNameRef.current = pathname
    }
  }, [pathname, onPathnameChange])
}
