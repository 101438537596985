const timeFormatter = (time: Date | number) => {
  const timeFormat = new Intl.DateTimeFormat('sv-SE', {
    timeStyle: 'short',
    hourCycle: 'h23',
  })

  return timeFormat.format(time)
}

export default timeFormatter
