import { isSupportedLang } from '@/src/utils/middleware/middlewareHelper.ts'

// Helper function to append lang to URL
export const appendLangToUrl = (url: string, lang: string) => {
  if (isSupportedLang(url)) return url

  if (url.startsWith('http')) return url

  return `/${lang}${url.startsWith('/') ? url : `/${url}`}`
}
