import timeFormatter from '@/src/utils/services/timeFormatter.ts'
import { useEffect, useState } from 'react'

const useClock = () => {
  const [time, setTime] = useState<string>('')

  const updateTime = () => {
    const formattedTime = timeFormatter(new Date().getTime())
    setTime(formattedTime)
  }

  useEffect(() => {
    const interval = setInterval(updateTime, 60000)
    updateTime()

    return () => {
      clearInterval(interval)
    }
  }, [])
  return { time }
}

export default useClock
