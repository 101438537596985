'use client'
// context/LanguageContext.tsx
import I18N from '@/src/app/_i18n/i18nConfig.ts'
import { SupportedLangsType } from '@/src/app/_i18n/settings.tsx'
import React, { createContext, ReactNode, useContext } from 'react'

interface LanguageContextType {
  lang: SupportedLangsType
}

export const LanguageProvider: React.FC<{
  children: ReactNode
  lang: SupportedLangsType
}> = ({ children, lang }) => {
  return (
    <LanguageContext.Provider value={{ lang }}>
      {children}
    </LanguageContext.Provider>
  )
}

const LanguageContext = createContext<LanguageContextType>({
  lang: I18N.i18n.defaultLocale,
})

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext)
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider')
  }
  return context
}
