'use client'
import { createContext, useContext, useMemo, useState } from 'react'

type UIContextProps = {
  toggleLoader: (_toggle: boolean) => void
  isLoaderOpen: boolean
}

const UIContext = createContext<UIContextProps>({} as UIContextProps)
export const useUIContext = () => useContext(UIContext)

type UIContextProviderProps = {
  children: React.ReactNode
}

export const UIContextProvider = ({ children }: UIContextProviderProps) => {
  const [state, setState] = useState({
    isLoaderOpen: false,
  })
  const value = useMemo(() => {
    const toggleLoader = (toggle?: boolean) =>
      setState({
        ...state,
        isLoaderOpen:
          typeof toggle === 'undefined' ? !state.isLoaderOpen : toggle,
      })

    return {
      ...state,
      toggleLoader,
    }
  }, [state])

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}
