'use client'
import { useMyAffiliateMutation } from '@/src/services/player'
import { setAffiliateId, setAffiliateToken } from '@/src/utils/localStorage'
import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

const MyAffiliate = () => {
  const searchParams = useSearchParams()
  const [initMyAffiliate] = useMyAffiliateMutation()
  useEffect(() => {
    const user_id = searchParams?.get('user_id')
    const token = searchParams?.get('token')
    if (!user_id || !token) return
    const slicedToken = token.slice(0, 60).toString()
    const slicedUserId = user_id.slice(0, 60).toString()
    setAffiliateToken(slicedToken)
    setAffiliateId(slicedUserId)
    initMyAffiliate({
      myAffiliateToken: slicedToken,
      myAffiliateId: slicedUserId,
    })
  }, [searchParams, initMyAffiliate])
  return null
}

export default MyAffiliate
