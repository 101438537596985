const I18N = {
  debug: false,
  i18n: {
    locales: ['sv'],
    mapPrimsicToNext: {
      '*': '*',
      sv: 'sv-SE',
    },
    defaultLocale: 'sv',
    localeDetection: false,
  },
  compatibilityJSON: 'v3', // https://my.trackjs.com/errors/filtered?hash=4eb150b9fa46fa51
  reloadOnPrerender: false,
} as const

export default I18N
