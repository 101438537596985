import { fusionUrl } from '@/src/services'
import {
  AuthenticateType,
  GetAllNotificationsType,
  GetSpecificNotificationType,
} from '@/src/services/types/fastTrack'

export type SpecificType = {
  token: string | undefined
  id: string | string[] | undefined
}

export type MarkType = {
  token: string | undefined
  MessageId: number
}

export type DummyResponse = {
  dummyResponse: string
}

export type PusherInput = {
  Data: AuthenticateType | undefined
}

export type PusherFTDataType = {
  CTAButton2Link: string | undefined
  CTAButton2Text: string | undefined
  CTAButtonLink: string | undefined
  CTAButtonText: string | undefined
  Data: {
    OverrideCommunicationStatus: string | undefined
  }
  Date: Date
  DisplayType: string | undefined
  Event: string | undefined
  Expires?: string | undefined
  FooterText: string | undefined
  ImageUrl: string | undefined
  IsRead: boolean | undefined
  Message: string | undefined
  MessageId: number | undefined
  PreviewText: string | undefined
  Title: string | undefined
}

export type PusherConnect = {
  socket_id?: string | undefined
}

const fusionUrlApi = fusionUrl.injectEndpoints({
  endpoints: (builder) => ({
    getFtAuth: builder.mutation<AuthenticateType, string>({
      query: (token) => {
        return {
          url: `/Platform/LoginAuthToken`,
          method: 'POST',
          headers: {
            authtoken: token,
          },
        }
      },
      invalidatesTags: ['Notifications', 'Notification'],
    }),
    getAllNotifications: builder.query<
      GetAllNotificationsType,
      string | undefined
    >({
      query: (token) => {
        return {
          url: `/Notifications/v2/user-notifications`,
          headers: {
            authtoken: token,
          },
        }
      },
      providesTags: ['Notifications'],
    }),
    getSpecificNotification: builder.query<
      GetSpecificNotificationType,
      SpecificType
    >({
      query: ({ token, id }) => {
        return {
          url: `/Notifications/v2/user-notification/${id}`,
          headers: {
            authtoken: token,
          },
        }
      },
      providesTags: ['Notification'],
    }),
    deletePost: builder.mutation<AuthenticateType, SpecificType>({
      query: ({ token, id }) => {
        return {
          url: `/Notifications/v2/user-notification/${id}`,
          method: 'DELETE',
          headers: {
            authtoken: token,
          },
        }
      },
      invalidatesTags: ['Notifications', 'Notification'],
    }),
    markAsRead: builder.mutation<AuthenticateType, MarkType>({
      query: ({ token, MessageId }) => {
        return {
          url: `/Notifications/MarkNotificationAsRead`,
          method: 'POST',
          headers: {
            authtoken: token,
          },
          body: { MessageId },
        }
      },
      invalidatesTags: ['Notifications', 'Notification'],
    }),
  }),
  overrideExisting: process.env.NODE_ENV === 'development',
})

export const {
  useGetFtAuthMutation,
  useGetAllNotificationsQuery,
  useGetSpecificNotificationQuery,
  useDeletePostMutation,
  useMarkAsReadMutation,
} = fusionUrlApi

//SSR endpoints
//export const { getConfig } = fastTrackApi.endpoints
export default fusionUrlApi
